/*
IMPORTANT:
In order to preserve the uniform grid appearance, all cell styles need to have padding, margin and border sizes.
No built-in (selected, editable, highlight, flashing, invalid, loading, :focus) or user-specified CSS
classes should alter those!
*/

.slick-header-columns {
  background: url('images/header-columns-bg.gif') repeat-x center bottom;
  border-bottom: 1px solid silver;
}

.slick-header-column {
  background: url('images/header-columns-bg.gif') repeat-x center bottom;
  border-right: 1px solid silver;
}

.slick-header-column:hover, .slick-header-column-active {
  background: white url('images/header-columns-over-bg.gif') repeat-x center bottom;
}

.slick-headerrow {
  background: #fafafa;
}

.slick-headerrow-column {
  background: #fafafa;
  border-bottom: 0;
  height: 100%;
}

.slick-row.ui-state-active {
  background: #F5F7D7;
}

.slick-row {
  position: absolute;
  background: white;
  border: 0px;
  line-height: 20px;
}

.slick-row.selected {
  z-index: 10;
  background: #DFE8F6;
}

.slick-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.slick-group {
  border-bottom: 2px solid silver;
}

.slick-group-toggle {
  width: 9px;
  height: 9px;
  margin-right: 5px;
}

.slick-group-toggle.expanded {
  background: url(images/collapse.gif) no-repeat center center;
}

.slick-group-toggle.collapsed {
  background: url(images/expand.gif) no-repeat center center;
}

.slick-group-totals {
  color: gray;
  background: white;
}

.slick-group-select-checkbox {
  width: 13px;
  height: 13px;
  margin: 3px 10px 0 0;
  display: inline-block;
}
.slick-group-select-checkbox.checked {
  background: url(images/GrpCheckboxY.png) no-repeat center center;
}

.slick-group-select-checkbox.unchecked {
  background: url(images/GrpCheckboxN.png) no-repeat center center;
}

.slick-cell.selected {
  background-color: beige;
}

.slick-cell.active {
  border-color: gray;
  border-style: solid;
}

.slick-sortable-placeholder {
  background: silver !important;
}

.slick-row.odd {
  background: #fafafa;
}

.slick-row.ui-state-active {
  background: #F5F7D7;
}

.slick-row.loading {
  opacity: 0.5;
  filter: alpha(opacity = 50);
}

.slick-cell.invalid {
  border-color: red;
  -moz-animation-duration: 0.2s;
  -webkit-animation-duration: 0.2s;
  -moz-animation-name: slickgrid-invalid-hilite;
  -webkit-animation-name: slickgrid-invalid-hilite;  
}

@-moz-keyframes slickgrid-invalid-hilite {
  from { box-shadow: 0 0 6px red; }
  to { box-shadow: none; }
}

@-webkit-keyframes slickgrid-invalid-hilite {
  from { box-shadow: 0 0 6px red; }
  to { box-shadow: none; }
}